<template>
  <InsetPanel
      v-if="!user"
      class="login-view"
      :title="t('title')">
    <FormPane
        ref="form"
        v-model:external-results="externalResults"
        :submit-label="t('submit')"
        :validator="validator"
        class="login-view__form"
        name="login"
        :submit="submit">
      <CSRFToken />
      <slot name="intro">
        <Paragraph>
          {{ t('intro') }}
        </Paragraph>
      </slot>
      <FieldSet>
        <Field
            :label="t('field.email.label')"
            :validator="validator.username"
            :required="!!validator.username.required"
            name="username">
          <template #default="{id, fieldName}">
            <TextInput
                :id="id"
                ref="emailField"
                v-model.trim="username"
                :name="fieldName"
                :validator="validator.username"
                :size="60"
                autofocus="autofocus"
                type="email"
                @update:model-value="validator.$clearExternalResults()" />
          </template>
          <template #error>
            <li
                v-if="validator.username.required.$invalid">
              {{ t('field.email.required') }}
            </li>
            <li
                v-if="validator.username.email.$invalid">
              {{ t('field.email.required') }}
            </li>
          </template>
        </Field>

        <Field
            :label="t('field.password.label')"
            :validator="validator.password"
            :required="!!validator.password.required"
            name="password">
          <template #default="{id, fieldName}">
            <TextInput
                :id="id"
                v-model="validator.password.$model"
                :validator="validator.password"
                type="password"
                :size="60"
                :name="fieldName"
                @update:model-value="validator.$clearExternalResults()" />
          </template>
          <template #error>
            <li
                v-if="validator.password.required.$invalid">
              {{ t('field.password.required') }}
            </li>
          </template>
        </Field>
      </FieldSet>
    </FormPane>

    <slot name="actions">
      <SectionPanel
          :title="t('reset')">
        <ActionPane>
          <Paragraph>
            {{ t('resetExplanation') }}
          </Paragraph>

          <template #buttons>
            <IconButton
                :to="{name: `${locale}:requestPasswordReset`}"
                type="secondary">
              {{ t('resetAccount') }}
            </IconButton>
          </template>
        </ActionPane>
      </SectionPanel>

      <SectionPanel
          :title="t('noAccount')">
        <ActionPane>
          <Paragraph>
            {{ t('noAccountExplanation') }}
          </Paragraph>

          <template #buttons>
            <IconButton
                :to="{name: `${locale}:register`}"
                type="secondary">
              {{ t('register') }}
            </IconButton>
          </template>
        </ActionPane>
      </SectionPanel>
    </slot>
  </InsetPanel>
</template>

<i18n>
{
  "nl": {
    "intro": "Met de inloggegevens van jouw account:",
    "noAccount": "Nog geen account?",
    "noAccountExplanation": "Indien je een sport wilt gaan beoefenen met jouw hond, kan je een account aanmaken.",
    "reset": "Wachtwoord vergeten?",
    "resetExplanation": "Dan kan je jouw wachtwoord opnieuw instellen met behulp van het e-mailadres.",
    "submit": "Log in",
    "title": "Inloggen",
    "register": "Een account aanmaken",
    "resetAccount": "Wachtwoord opnieuw instellen",
    "field": {
      "email": {
        "invalid": "Vul een e-mailadres in",
        "label": "E-mailadres",
        "required": "Vul een e-mailadres in"
      },
      "password": {
        "label": "Wachtwoord",
        "required": "Vul een wachtwoord in"
      }
    }
  },
  "en": {
    "intro": "With your account details:",
    "noAccount": "No account?",
    "noAccountExplanation": "If you want to compete in a Dutch show, you can create an account.",
    "reset": "Forgot your password?",
    "resetExplanation": "You can reset your password with your e-mail address.",
    "submit": "Log in",
    "title": "Log in",
    "register": "Create an account",
    "resetAccount": "Reset password",
    "field": {
      "email": {
        "invalid": "Please fill in an e-mail address",
        "label": "E-mail address",
        "required": "Please fill in an e-mail address"
      },
      "password": {
        "label": "Password",
        "required": "Enter the password"
      }
    }
  }
}
</i18n>
<script>
import { useVuelidate } from '@vuelidate/core';
import {
  email,
  required,
} from '@vuelidate/validators';

import {
  computed,
  onMounted,
  ref,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useHead } from '@unhead/vue';
import { testExternal } from '../../utils/vuelidate.js';
import useSession from '../../session/session.js';
import SectionPanel from '../../components/panels/SectionPanel.vue';
import Paragraph from '../../components/typography/Paragraph.vue';
import IconButton from '../../components/buttons/IconButton.vue';
import FieldSet from '../../components/fieldsets/FieldSet.vue';
import Field from '../../components/fields/FormField.vue';
import TextInput from '../../components/widgets/forms/TextInput.vue';
import CSRFToken from '../../components/widgets/forms/CSRFToken.vue';
import InsetPanel from '../../components/panels/InsetPanel.vue';
import FormPane from '../../components/forms/FormPane.vue';
import ActionPane from '../../components/panels/ActionPane.vue';

export default {
  components: {
    ActionPane,
    FormPane,
    InsetPanel,
    CSRFToken,
    TextInput,
    Field,
    FieldSet,
    Paragraph,
    SectionPanel,
    IconButton,
  },

  setup() {
    const { user, login } = useSession();
    const route = useRoute();
    const router = useRouter();
    const emailField = ref(null);
    const { t, locale } = useI18n();

    const username = ref('');
    const password = ref('');
    const generalErrors = ref(false);

    const rules = computed(() => ({
      null: { testExternal },
      password: {
        required,
      },
      username: {
        email,
        required,
        $lazy: true,
      },
    }));

    const externalResults = ref({});
    const validator = useVuelidate(
      rules,
      { username, password },
      { $externalResults: externalResults },
    );

    onMounted(() => {
      emailField.value?.$el.focus();
    });

    useHead({
      title: t('title'),
    });

    return {
      generalErrors,
      user,
      emailField,
      validator,
      externalResults,

      username,
      password,

      t,
      locale,

      async submit() {
        const home = router.resolve({ name: `${locale.value}:home` }).fullPath;
        const next = await login(username.value, password.value, route.query.next || home);
        // Go to where the backend tells us to go
        if (next !== route.path) {
          await router.replace(next);
        }
      },
    };
  },

};
</script>

<style>
.login-view {
  align-self: center;
  justify-self: center;

  max-width: 25rem;
}
</style>
